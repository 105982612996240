<script setup lang="ts">
import { inject, computed, InputHTMLAttributes, useSlots, toRef } from 'vue';
import { useField } from 'vee-validate';
import { IIconProps } from '../ApzIcon/ApzIcon.vue';

export interface IInputProps extends /* @vue-ignore */ InputHTMLAttributes {
  name: string;
  modelValue?: string | number | null;
  label?: string;
  labelFor?: string;
  labelClass?: string;
  placeholder?: string;
  variant?: 'default' | 'outline' | 'flat';
  icon?: IIconProps['icon'];
  iconRight?: boolean;
  iconClass?: string;
  wrapperClass?: string;
  help?: string;
  required?: boolean;
  standalone?: boolean;
}

export interface IInputEmits {
  (e: 'update:modelValue', value: string | number | null): void;
}

const props = withDefaults(defineProps<IInputProps>(), {
  variant: 'default',
});
const emit = defineEmits<IInputEmits>();

const isGrouped = inject<boolean>('isGrouped', false);

const name = toRef(props, 'name', '');

const slots = useSlots();
const {
  value: inputValue,
  errorMessage: error,
  handleBlur,
  handleChange,
} = useField(name, undefined, { standalone: props.standalone, initialValue: props.modelValue, syncVModel: true });

const statusClasses = computed(() =>
  error.value && !isGrouped ? 'border-danger' : 'border-gray-light hover:border-gray-dark',
);

const variantClasses = computed(() => {
  if (props.variant === 'outline') {
    return 'bg-white border rounded pl-10';
  }

  if (props.variant === 'flat') {
    return 'bg-whiter rounded pl-10';
  }

  return 'bg-transparent pl-0 border-0 border-b focus:outline-none focus:ring-0 focus:border-gray-darkest';
});

const computedIcon = computed(() => {
  return typeof props.icon === 'string' ? (['fal', props.icon] as IIconProps['icon']) : props.icon;
});

const hasIcon = computed(() => {
  return (props.icon && !props.iconRight) || slots.icon;
});

function onChange(e: Event & { target: HTMLSelectElement }) {
  handleChange(e.target.value, true);
  emit('update:modelValue', e.target.value);
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div :class="wrapperClass">
    <label
      v-if="label && !isGrouped"
      :for="labelFor ?? name"
      class="block text-small mb-5"
      :class="[labelClass]"
    >
      <slot name="label">
        {{ label }}

        <span
          v-if="required"
          class="text-primary relative -left-3"
        >
          *
        </span>
      </slot>
    </label>

    <div class="relative">
      <select
        :id="labelFor ?? name"
        :value="inputValue"
        class="w-full h-35 py-0 text-base placeholder-gray-light disabled:opacity-50"
        :class="[
          variantClasses,
          statusClasses,
          $attrs.class,
          !inputValue ? 'text-gray-light' : 'text-gray-darkest',
          { '!pl-35': hasIcon, 'font-bold': inputValue },
        ]"
        @change="onChange"
        @blur="handleBlur"
      >
        <option
          v-if="placeholder"
          value=""
          disabled
        >
          {{ placeholder }}
        </option>

        <slot />
      </select>

      <span
        v-if="hasIcon"
        class="text-icon absolute bottom-0 left-0 h-35 w-30 flex items-center justify-center pointer-events-none"
        :class="iconClass"
      >
        <slot name="icon">
          <ApzIcon :icon="computedIcon" />
        </slot>
      </span>
    </div>

    <p
      v-if="(error || help) && !isGrouped"
      class="text-small mt-10"
      :class="[error ? 'text-danger' : 'text-gray-dark']"
    >
      <slot name="message">
        {{ error || help }}
      </slot>
    </p>
  </div>
</template>
